import $ from 'jquery';

export default (container?: HTMLElement) => {
    (container ?? document).querySelectorAll('.accordion__title').forEach((el) => {
        if ((el as any).hasAccordion === true) {
            return;
        }

        (el as any).hasAccordion = true;

        const wrapper = el.closest('.accordion__wrapper');

        const makeItemActive = (activeItem: Element) => {
            const items = wrapper?.querySelectorAll('.accordion__title') ?? [];

            items.forEach((item) => {
                if (item === activeItem && !item.classList.contains('active')) {
                    item.classList.add('active');
                    $(item.parentElement.querySelector('.accordion__content')).slideDown();
                } else {
                    item.classList.remove('active');
                    $(item.parentElement.querySelector('.accordion__content')).slideUp();
                }
            });
        };

        el.addEventListener('click', () => makeItemActive(el));
    });
};

// @ts-ignore
import baguetteBox from 'baguettebox.js';
import Swiper, { Navigation, Pagination } from 'swiper';

export default () => {
    document.querySelectorAll('.swiper-container-wly').forEach((el) => {
        let init = false;

        const swiper = new Swiper(el as HTMLElement, {
            modules: [Navigation, Pagination],
            // Optional parameters
            direction: 'horizontal',
            loop: true,
            speed: 1400,
            autoplay: {
                delay: 7000
            },
            grabCursor: true,
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            }
        });

        swiper.on('init', () => {
            baguetteBox.run('.swiper-wrapper');
        });

        el.addEventListener('lazyloaded', () => {
            if (!init) {
                swiper.init();
                init = true;
            }

            swiper.updateAutoHeight();
        });
    });


    document.querySelectorAll('.swiper-container-product-teaser').forEach((el) => {
        let init = false;

        const swiper = new Swiper(el as HTMLElement, {
            modules: [Navigation],
            // Optional parameters
            direction: 'horizontal',
            speed: 1400,
            autoplay: {
                delay: 7000
            },
            slidesPerView: 1,
            spaceBetween: 15,
            breakpoints: {
                1201: {
                    slidesPerView: 3,
                    spaceBetween: 30
                },
                768: {
                    slidesPerView: 2
                }
            },
            grabCursor: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            }
        });

        el.addEventListener('lazyloaded', () => {
            if (!init) {
                swiper.init();
                init = true;
            }
        });
    });


    document.querySelectorAll('.swiper-container-logo-slider').forEach((el) => {
        let init = false;

        const swiper = new Swiper(el as HTMLElement, {
            modules: [Navigation],
            // Optional parameters
            direction: 'horizontal',
            speed: 1400,
            autoplay: {
                delay: 7000
            },
            slidesPerView: 1,
            spaceBetween: 30,
            breakpoints: {
                1201: {
                    slidesPerView: 4
                },
                993: {
                    slidesPerView: 3
                },
                501: {
                    slidesPerView: 2
                }
            },
            grabCursor: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            }
        });

        el.addEventListener('lazyloaded', () => {
            if (!init) {
                swiper.init();
                init = true;
            }
        });
    });
};


// @ts-ignore
import Alpine from 'alpinejs';

if (!window.Alpine) {
    window.Alpine = Alpine;
}

export const startAlpine = () => {
    Alpine.start();
};

import Menu from 'mmenu-js';

document.addEventListener('DOMContentLoaded', () => {

    const template = document.querySelector('#mmenu-template').innerHTML

    new Menu('#wlymmenu', {
        wrappers: ['bootstrap'],
        extensions: ['fx-listitems-fade', 'border-offset', 'pagedim-black', 'theme-black'],
        onClick: {
            close: true,
            setSelected: true,
        },
        slidingSubmenus: true,
        navbars: [
            {
                'position': 'bottom',
                'content': [template]
            }
        ],
    });
});

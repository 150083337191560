import { startAlpine } from '@/alpine/boot';
import { registerComponent } from '@/alpine/helper';

import { FixedHeaderComponent } from '@/alpine/components/header';
import { FormComponent } from '@/alpine/components/form';
import { AvailabilityChecker } from '@/alpine/components/availability-checker';

registerComponent('FixedHeader', FixedHeaderComponent);
registerComponent('Form', FormComponent);
registerComponent('AvailabilityChecker', AvailabilityChecker);

// Start the Alpine Engine
document.addEventListener(
    'DOMContentLoaded',
    () => {
        startAlpine();
    },
    false
);

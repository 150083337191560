import { ErrorMessage, Field, Form } from 'vee-validate';
import { defineAsyncComponent } from 'vue';

export const registerComponents = (app: any) => {
    app.component(
        'app-order',
        defineAsyncComponent(() => import('@/vue/components/Order.vue'))
    );

    app.component('Form', Form);
    app.component('Field', Field);
    app.component('ErrorMessage', ErrorMessage);
};

import $ from 'jquery';

$(() => {
    // Tabs
    $('.tabs__title').click(function() {
        var currentTitel = $(this);
        $('.tabs__title').removeClass('active').addClass('notactive');
        currentTitel.addClass('active').removeClass('notactive');

        $('.tabs__item').removeClass('active').addClass('notactive');
        currentTitel.closest('.tabs__item').addClass('active').removeClass('notactive');
    });


    // Livesearch FAQ / Manuals

    $('#live-search-input').keyup(function(){
        var re = new RegExp($('#live-search-input').val(), 'i')
        $('.link-list__item').each(function(){
            if($(this).children('.link-list__title')[0].innerHTML.match(re)){
                $(this).show();
            }else{
                $(this).hide();
            };
        });
        faqCounter();
    });

    // FAQ / Manuals Counter Function
    function faqCounter() {
        $('.tabs__wrapper .tabs__item').each(function() {
            var count_elements = $(this).find('.link-list__item:not([style*="display: none"])').length;
            $(this).find('.tabs__title .counter').html(count_elements);
        });
    }
    faqCounter();


    // Navigation Touch Geräte
    $('.main__navigation ul.navbar-nav > li.nav-menu > a').on('touchend', function (e) {
        if (!$(this).parent().hasClass('hovered')) {
            e.preventDefault();
            $(this).parent().toggleClass('hovered');
            $(this).parent().siblings().removeClass('hovered');
        }
    });

    //Make Header fixed
    let scroll = $(window).scrollTop();
    const body = $('body');

    $(window).on('scroll', () => {
        scroll = $(window).scrollTop();
        if (scroll >= 150) {
            body.addClass("fixed--header");
            $('.global-news-stopper').addClass('deactivated');
        } else {
            body.removeClass("fixed--header");
            $('.global-news-stopper').removeClass('deactivated');
        }
    });

    // Product Teaser Header same height
    function productTeaserHeader() {
        $('.product-teaser-modul').each(function() {
            if($(this).find('.box-type--accordion').length === 0) {
                var tallestHeight = 0;
                $(this).find(".box__inner").each(function(){
                    $(this).outerHeight("auto");
                    if(parseInt($(this).outerHeight(true)) > tallestHeight){
                        tallestHeight = parseInt($(this).outerHeight(true));
                    }
                });
                $(this).find(".box__inner").outerHeight(tallestHeight);
            }
        });
    }
    productTeaserHeader();

    $(window).on('resize', function(){
        productTeaserHeader();
    });
});

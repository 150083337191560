let initialized = false;
const callbacks: any[] = [];

document.addEventListener('DOMContentLoaded', (value) => {
    callbacks.forEach((callback) => callback());
    initialized = true;
});

export const onReady = (callback: any) => {
    if (typeof callback !== 'function') {
        throw Error('Callback must be function.');
    }

    if (initialized) {
        callback();
    } else {
        callbacks.push(callback);
    }
};

import { AlpineComponent } from '@/alpine/helper';

export class AvailabilityChecker extends AlpineComponent {
    searchTerm = '';

    isValid: boolean | null = null;
    selectedResult: any = null;
    showResults = false;

    locationEntries: Array<{
        zip: number;
        location: string;
        limited_service: boolean;
    }> = [];

    resultMessage = '';
    successMessage = '';
    limitedServiceMessage = '';
    errorMessage: string = '';

    constructor(params?: Record<'zipList' | 'successMessage' | 'limitedServiceMessage' | 'errorMessage' | 'locationEntries', any>) {
        super();

        this.zipList = params?.zipList ?? [];
        this.successMessage = params?.successMessage ?? null;
        this.limitedServiceMessage = params?.limitedServiceMessage ?? null;
        this.errorMessage = params?.errorMessage ?? null;
        this.locationEntries = params?.locationEntries ?? [];
    }

    // checkAvailability() {
    //     if (!this.searchTerm) {
    //         this.selectedResult = null;
    //         this.isValid = null;
    //
    //         return;
    //     }
    //
    //     this.selectedResult = this.locationEntries.map((e) => e.zip).find((zip) => zip === parseInt(this.searchTerm));
    //     this.isValid = !!this.selectedResult;
    //     this.resultMessage = (this.isValid ? this.successMessage : this.errorMessage).replace('%INPUT%', this.searchTerm);
    // }

    selectOption(result) {
        this.selectedResult = result;
        this.searchTerm = `${result.zip} ${result.location}`;
        this.isValid = true;

        this.resultMessage = (result.limited_service ? this.limitedServiceMessage : this.successMessage).replace(
            '%INPUT%',
            this.searchTerm
        );

        this.showResults = false;
    }

    results() {
        return this.locationEntries.filter((entry) => {
            return !this.searchTerm ||  (
                this.searchTerm.match(/\S.*/) &&
                `${entry.zip} ${entry.location}`.toLowerCase().includes(this.searchTerm.toLowerCase().trim())
            );
        });
    }
}

import { configure, defineRule } from 'vee-validate';
import AllRules from '@vee-validate/rules';
import { setLocale } from 'yup';

Object.keys(AllRules).forEach((rule) => {
    defineRule(rule, AllRules[rule]);
});

setLocale({
    mixed: {
        default: () => 'Fehlerhafte Eingabe',
        required: () => 'Dies ist ein Pflichtfeld',
    },
    string: {
        email: 'Die E-Mail-Adresse ist falsch',
    },
});

configure({
    validateOnBlur: true,
    validateOnModelUpdate: true,
    validateOnChange: false,
    validateOnInput: false,
});
